/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.auto-img {
  position: relative;
  display: inline-block;
  overflow: hidden;
}
.auto-img .toolbar-preview {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 25px;
  background: #000000;
  opacity: 0.7;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.auto-img .auto-img-error {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.auto-img .auto-img-error .auto-img-error-text {
  width: 80%;
  max-width: 100px;
  pointer-events: none;
}
