/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
@tailwind components;
@tailwind utilities;
#nprogress {
  pointer-events: none;
}
#nprogress .bar {
  background: #2d63ff;
  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
}
/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #2d63ff, 0 0 5px #2d63ff;
  opacity: 1;
  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}
/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}
#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  border: solid 2px transparent;
  border-top-color: #2d63ff;
  border-left-color: #2d63ff;
  border-radius: 50%;
  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
}
.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}
.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}
@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
#nprogress .bar {
  background: #fff627 !important;
}
#nprogress .peg {
  box-shadow: unset !important;
}
.ui-page {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.ui-page.ui-safeArea {
  top: var(--safe-area-inset-top);
  right: var(--safe-area-inset-right);
  bottom: var(--safe-area-inset-bottom);
  left: var(--safe-area-inset-left);
}
.ui-content {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.ui-content.ui-content-auto-scroll {
  min-height: unset;
  height: 100%;
}
.ui-content.ui-content-auto-scroll > .ui-content-auto {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  flex: 1 0 0;
}
.ui-content > .ui-content-auto {
  flex: 1 0 auto;
}
.ui-content > .ui-content-fix {
  flex: 0 0 auto;
}
@font-face {
  font-family: DIN-Bold;
  src: url('~@/assets/fonts/DIN-Bold.otf') format('opentype');
}
@font-face {
  font-family: DIN-Light;
  src: url('~@/assets/fonts/DIN-Light.otf') format('opentype');
}
body {
  overflow-y: hidden;
}
#app {
  height: 100%;
}
.ant-layout.layout-horizontal {
  flex-direction: row;
}
.content-main-holder-wrapper {
  position: relative;
  flex: 1;
  overflow: hidden;
}
.content-main-holder-wrapper .content-main-holder {
  height: 100%;
  overflow: auto;
}
.content-main-holder-wrapper .content-main-holder .content-main-holder-content .content-main {
  background: #fff;
  position: relative;
}
::-webkit-scrollbar,
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #f8f8f8;
}
::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: #cfd6dd;
}
::-webkit-scrollbar-thumb:hover,
::-webkit-scrollbar-thumb:hover {
  border-radius: 3px;
  box-shadow: inset 0 0 1px 1px rgba(65, 159, 244, 0.4);
  background: rgba(65, 159, 244, 0.6);
}
.ant-form.form-label-8 .ant-form-item-label {
  width: 9em;
}
.ant-form.form-label-7 .ant-form-item-label {
  width: 8em;
}
.ant-form.form-label-6 .ant-form-item-label {
  width: 7em;
}
.ant-form.form-label-5 .ant-form-item-label {
  width: 6em;
}
.ant-form.form-label-4 .ant-form-item-label {
  width: 5em;
}
.ant-form.flex-form .ant-form-item {
  display: flex;
}
.ant-form.flex-form .ant-form-item-label {
  flex: 0 0 auto;
}
.ant-form.flex-form .ant-form-item-control-wrapper {
  flex: 1 1 0;
}
.ant-form.flex-form .ant-form-item-control-wrapper .ant-input-number {
  width: 100%;
}
.a-form-mb-9 .ant-form-item {
  margin-bottom: 2.25rem !important;
}
.a-form-mb-8 .ant-form-item {
  margin-bottom: 2rem !important;
}
.a-form-mb-7 .ant-form-item {
  margin-bottom: 1.75rem !important;
}
.a-form-mb-6 .ant-form-item {
  margin-bottom: 1.5rem !important;
}
.a-form-mb-5 .ant-form-item {
  margin-bottom: 1.25rem !important;
}
.a-form-mb-4 .ant-form-item {
  margin-bottom: 1rem !important;
}
.a-form-mb-3 .ant-form-item {
  margin-bottom: 0.75rem !important;
}
.a-form-mb-2 .ant-form-item {
  margin-bottom: 0.5rem !important;
}
.a-form-mb-1 .ant-form-item {
  margin-bottom: 0.25rem !important;
}
.a-form-mb-0 .ant-form-item {
  margin-bottom: 0rem !important;
}
.form-control {
  width: 174px;
}
.form-control.ant-input,
.form-control.ant-select {
  width: 174px;
}
.form-control.lg {
  width: 250px;
}
.form-control.lg.ant-input,
.form-control.lg.ant-select {
  width: 250px;
}
.form-control.mutiple-select {
  width: auto;
  min-width: 174px;
}
.form-control.mutiple-select.lg {
  min-width: 250px;
}
.checkbox-vertical .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0;
  display: block;
}
.queryView .queryView-actions {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
.queryView .queryView-actions button {
  margin-right: 10px;
}
.queryView .queryView-actions button:last-child {
  margin-right: 0;
}
.queryView .queryView-conditions {
  margin-bottom: 25px;
}
.queryView .queryView-conditions .condition-btns {
  display: inline-block;
  white-space: nowrap;
  padding-left: 8px;
}
.queryView .queryView-conditions .condition-btns .ant-btn {
  margin-left: 10px;
}
.queryView .queryView-table:not(.multiRow-table) tbody tr:nth-child(even) {
  background: #f0f2f5;
}
.queryView .queryView-table .ant-table-thead > tr > th {
  background: #dbe0e7;
}
.queryView .queryView-table .ant-table-thead > tr > th.ant-table-column-sort {
  background: #dbe0e7;
}
.queryView .queryView-table.tight td,
.queryView .queryView-table.tight th {
  padding: 4px !important;
}
.queryView .queryView-table .operations-icon > .anticon {
  margin-right: 8px;
}
.queryView .queryView-table .operations-icon > .anticon:last-child {
  margin-right: 0;
}
.queryView .queryView-table .operations-icon > .anticon:hover {
  color: #2d63ff;
}
.queryView .queryView-table .row-action-btn {
  margin: 0 8px;
  white-space: nowrap;
}
.queryView .queryView-pagination {
  margin-top: 20px;
}
.line-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
.fs-page {
  background: #f0f2f5;
  position: absolute;
  overflow: auto;
  left: -16px;
  right: -16px;
  top: -16px;
  bottom: -16px;
}
.fs-page .fs-page-main {
  padding: 16px;
  min-height: 820px;
  height: 100%;
}
.location-input {
  display: flex;
}
.location-input .location-input-item {
  flex: 1;
}
.location-input .location-input-item:first-child input {
  border-radius: 4px 0 0 4px;
}
.location-input .location-input-item input {
  width: 100%;
  height: 32px;
  border-radius: 0;
  border-right: 0;
}
.location-input .location-input-addon {
  vertical-align: middle;
  padding: 4px 0;
}
.location-input .location-input-addon .location-input-addon-icon {
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  background: #ddd;
  border-radius: 0 4px 4px 0;
}
.amap-copyright {
  opacity: 0;
}
.ant-pagination {
  text-align: right;
}
.ant-pagination-options {
  margin-left: 0;
  float: left;
}
.ant-pagination ul,
.ant-pagination ol {
  text-align: center;
}
.has-error .ant-upload-select-picture-card {
  border: 1px dashed #f5222d;
}
.modal-scroll .ant-modal {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}
.modal-scroll .ant-modal-content {
  max-height: 100%;
}
.modal-scroll .ant-modal-body {
  max-height: calc(100vh - 250px);
  overflow: auto;
}
@screen sm {
  .modal-scroll .ant-modal-body {
    max-height: calc(100vh - 245px);
  }
}
@screen md {
  .modal-scroll .ant-modal-body {
    max-height: calc(100vh - 240px);
  }
}
@screen lg {
  .modal-scroll .ant-modal-body {
    max-height: calc(100vh - 235px);
  }
}
.modal-scroll.full-screen .ant-modal {
  top: 0;
  height: 100%;
  width: 100% !important;
  margin: 0 !important;
  max-width: 100% !important;
}
.modal-scroll.full-screen .ant-modal-content {
  max-height: calc(100vh) !important;
  border-radius: 0;
}
.modal-scroll.full-screen .ant-modal-body {
  max-height: calc(100vh - 110px) !important;
  height: calc(100vh - 110px) !important;
}
.page-top-nav {
  background: #eee;
  overflow: hidden;
}
.page-top-nav > .ant-tabs-card > .ant-tabs-content {
  margin-top: -16px;
}
.page-top-nav > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
  background: #fff;
  padding: 16px;
}
.page-top-nav > .ant-tabs-card > .ant-tabs-bar {
  border-color: #fff;
}
.page-top-nav > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
  border-color: transparent;
  background: transparent;
}
.page-top-nav > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
  border-color: #fff;
  background: #fff;
}
.pageContent-title {
  position: relative;
  line-height: 26px;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
  padding-left: 20px;
}
.pageContent-title:before {
  content: '';
  width: 4px;
  height: 26px;
  position: absolute;
  left: 0px;
  background: #2d63ff;
}
