@font-face {
  font-family: DIN-Bold;
  src: url('~@/assets/fonts/DIN-Bold.otf') format('opentype');
}

@font-face {
  font-family: DIN-Light;
  src: url('~@/assets/fonts/DIN-Light.otf') format('opentype');
}

body {
  overflow-y: hidden;
}

#app {
  height: 100%;
}

.ant-layout.layout-horizontal {
  flex-direction: row;
}

.content-main-holder-wrapper {
  position: relative;
  flex: 1;
  overflow: hidden;

  .content-main-holder {
    height: 100%;
    overflow: auto;

    .content-main-holder-content {
      .content-main {
        background: #fff;
        position: relative;
      }
    }
  }
}
::-webkit-scrollbar,
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #f8f8f8;
}

::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: #cfd6dd;
}

::-webkit-scrollbar-thumb:hover,
::-webkit-scrollbar-thumb:hover {
  border-radius: 3px;
  box-shadow: inset 0 0 1px 1px rgba(65, 159, 244, 0.4);
  background: rgba(65, 159, 244, 0.6);
}
.ant-form {
  &.form-label-8 {
    .ant-form-item-label {
      width: 9em;
    }
  }
  &.form-label-7 {
    .ant-form-item-label {
      width: 8em;
    }
  }
  &.form-label-6 {
    .ant-form-item-label {
      width: 7em;
    }
  }
  &.form-label-5 {
    .ant-form-item-label {
      width: 6em;
    }
  }
  &.form-label-4 {
    .ant-form-item-label {
      width: 5em;
    }
  }
  &.flex-form {
    .ant-form-item {
      display: flex;
    }
    .ant-form-item-label {
      flex: 0 0 auto;
    }
    .ant-form-item-control-wrapper {
      flex: 1 1 0;
      .ant-input-number {
        width: 100%;
      }
    }
  }
}
.loop(@i) when(@i<10) {
  //递归调用 达到循环的目的
  .loop((@i+1));
  .a-form-mb-@{i} .ant-form-item {
    margin-bottom: @i * 0.25rem !important;
  }
}
.loop(0);

.form-control {
  width: 174px;
  &.ant-input,
  &.ant-select {
    width: 174px;
  }
  &.lg {
    width: 250px;
    &.ant-input,
    &.ant-select {
      width: 250px;
    }
  }
  &.mutiple-select {
    width: auto;
    min-width: 174px;
    &.lg {
      min-width: 250px;
    }
  }
}

.checkbox-vertical {
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0;
    display: block;
  }
}
.queryView {
  .queryView-actions {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
    button {
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  .queryView-conditions {
    margin-bottom: 25px;

    .condition-btns {
      display: inline-block;
      white-space: nowrap;
      padding-left: 8px;
      .ant-btn {
        margin-left: 10px;
      }
    }
  }

  .queryView-table {
    &:not(.multiRow-table) {
      tbody {
        // background: red;
        tr:nth-child(even) {
          background: #f0f2f5;
        }
      }
    }
    .ant-table-thead > tr > th {
      background: #dbe0e7;
    }
    .ant-table-thead > tr > th.ant-table-column-sort {
      background: #dbe0e7;
    }
    &.tight {
      td,
      th {
        padding: 4px !important;
      }
    }

    .operations-icon {
      > .anticon {
        margin-right: 8px;

        &:last-child {
          margin-right: 0;
        }

        &:hover {
          color: @primary-color;
        }
      }
    }

    .row-action-btn {
      margin: 0 8px;
      white-space: nowrap;
    }
  }

  .queryView-pagination {
    margin-top: 20px;
  }
}

// 全局类
.line-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.fs-page {
  background: #f0f2f5;
  position: absolute;
  overflow: auto;
  left: -16px;
  right: -16px;
  top: -16px;
  bottom: -16px;

  .fs-page-main {
    padding: 16px;
    min-height: 820px;
    height: 100%;
  }
}

.location-input {
  display: flex;

  .location-input-item {
    flex: 1;

    &:first-child {
      input {
        border-radius: 4px 0 0 4px;
      }
    }

    input {
      width: 100%;
      height: 32px;
      border-radius: 0;
      border-right: 0;
    }
  }

  .location-input-addon {
    vertical-align: middle;
    padding: 4px 0;

    .location-input-addon-icon {
      width: 32px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      background: #ddd;
      border-radius: 0 4px 4px 0;
    }
  }
}

.amap-copyright {
  opacity: 0;
}

// S 全局分页样式，页码居右，跳转居左
.ant-pagination {
  text-align: right;
}

.ant-pagination-options {
  margin-left: 0;
  float: left;
}

.ant-pagination ul,
.ant-pagination ol {
  text-align: center;
}

// E 全局分页样式

.has-error {
  .ant-upload-select-picture-card {
    border: 1px dashed #f5222d;
  }
}

.modal-scroll {
  // .ant-modal-wrap {
  //   display: flex;
  //   align-items: center;
  // }
  .ant-modal {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .ant-modal-content {
    max-height: 100%;
  }
  .ant-modal-body {
    max-height: calc(100vh - 250px);
    overflow: auto;
  }
}

@screen sm {
  .modal-scroll {
    .ant-modal-body {
      max-height: calc(100vh - 245px);
    }
  }
}
@screen md {
  .modal-scroll {
    .ant-modal-body {
      max-height: calc(100vh - 240px);
    }
  }
}
@screen lg {
  .modal-scroll {
    .ant-modal-body {
      max-height: calc(100vh - 235px);
    }
  }
}

.modal-scroll.full-screen {
  .ant-modal {
    top: 0;
    height: 100%;
    width: 100% !important;
    margin: 0 !important;
    max-width: 100% !important;
  }
  .ant-modal-content {
    max-height: calc(100vh) !important;
    border-radius: 0;
  }
  .ant-modal-body {
    max-height: calc(100vh - 110px) !important;
    height: calc(100vh - 110px) !important;
  }
}
.page-top-nav {
  background: #eee;
  overflow: hidden;
  > .ant-tabs-card > .ant-tabs-content {
    margin-top: -16px;
  }
  > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
    background: #fff;
    padding: 16px;
  }
  > .ant-tabs-card > .ant-tabs-bar {
    border-color: #fff;
  }
  > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
    border-color: transparent;
    background: transparent;
  }
  > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
    border-color: #fff;
    background: #fff;
  }
}
.pageContent-title {
  position: relative;
  line-height: 26px;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
  padding-left: 20px;
  &:before {
    content: '';
    width: 4px;
    height: 26px;
    position: absolute;
    left: 0px;
    background: @primary-color;
  }
}
