.ui-page {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  &.ui-safeArea {
    top: var(--safe-area-inset-top);
    right: var(--safe-area-inset-right);
    bottom: var(--safe-area-inset-bottom);
    left: var(--safe-area-inset-left);
  }
}

.ui-content {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  &.ui-content-auto-scroll {
    min-height: unset;
    height: 100%;
    > .ui-content-auto {
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      flex: 1 0 0;
    }
  }

  > .ui-content-auto {
    flex: 1 0 auto;
  }

  > .ui-content-fix {
    flex: 0 0 auto;
  }
}
