/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.site-menu {
  overflow: auto;
  background: #eaedf1;
}
.site-menu .menu-level-0 {
  color: #3a3a3a;
  position: relative;
}
.site-menu .menu-level-0.has-children .title {
  padding-left: 36px;
  font-size: 16px;
  line-height: 55px;
  position: relative;
  border-bottom: 1px solid #e4e4e4;
  background: #f6f7fa;
}
.site-menu .menu-level-0.has-children .title .title-text {
  margin-left: 12px;
}
.site-menu .menu-level-0.active:not(.has-children) > .title {
  color: #2d63ff;
}
.site-menu .menu-level-0.active:not(.has-children) > .title:before {
  background: #2d63ff;
}
.site-menu .menu-level-0:not(.has-children) > .title {
  line-height: 55px;
  padding-left: 36px;
  font-size: 16px;
  color: #333;
  cursor: pointer;
}
.site-menu .menu-level-0 .affix-menu-list {
  background: #eaedf1;
}
.site-menu .menu-level-0 .affix-menu-list .menu-item {
  font-size: 14px;
  line-height: 40px;
  height: 40px;
  border-bottom: 1px solid #e4e4e4;
  padding-left: 50px;
  cursor: pointer;
}
.site-menu .menu-level-0 .affix-menu-list .menu-item.active {
  color: #2d63ff;
}
.site-menu .menu-level-0 .affix-menu-list .menu-item.active:before {
  background: #2d63ff;
}
.site-menu .menu-level-0 .affix-menu-list .menu-item a {
  position: relative;
  color: #666;
}
.site-menu .menu-level-0 .affix-menu-list .menu-item a:before {
  content: '';
  position: absolute;
  left: -8px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background: #666;
}
.site-menu .menu-level-0 .affix-menu-list .menu-item a:hover {
  color: #2d63ff;
}
.site-menu > .menu-item {
  padding: 0 30px;
  line-height: 55px;
}
