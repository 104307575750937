/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.upload-wrapper {
  position: relative;
}
.upload-wrapper .upload-delete-btn {
  width: 16px;
  height: 16px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 5;
  background: rgba(0, 0, 0, 0.15);
  padding: 2px;
}
.upload-wrapper .upload-delete-btn > svg {
  display: block;
}
.upload-wrapper .upload-main-container {
  position: relative;
}
.upload-wrapper .upload-main-container.disabled {
  cursor: not-allowed;
}
.upload-wrapper .upload-main-container.disabled .type-container-bg-wrapper {
  pointer-events: none;
}
.upload-wrapper .upload-main-container .upload-main {
  width: 100%;
  height: 100%;
}
.upload-wrapper .upload-main-container .upload-main .img-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.upload-wrapper .upload-main-container .upload-main .type-container {
  width: 100%;
  height: 100%;
  position: relative;
}
.upload-wrapper .upload-extra-container {
  position: absolute;
  top: 0;
  width: 0;
}
.upload-wrapper .upload-extra-container .extra-row {
  margin-bottom: 10px;
}
.upload-wrapper .upload-extra-container.small .extra-row {
  margin-bottom: 5px;
}
.type-video {
  position: relative;
}
.type-video .shroud {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
}
.type-video .shroud .play-icon {
  width: 40%;
  padding-top: 40%;
  border: 1px solid #fff;
  border-radius: 50%;
  position: relative;
}
.type-video .shroud .play-icon .icon-main {
  width: 100%;
  height: 100%;
  padding-left: 10%;
  position: absolute;
  top: 0;
  left: 0;
}
.type-other {
  display: flex;
  align-items: center;
  justify-content: center;
}
.type-other .preview-link {
  display: block;
  word-break: break-all;
  max-width: 80%;
}
