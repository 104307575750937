/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.nickname {
  margin-left: 6px;
}
.app-info-menu {
  margin: -8px 0;
}
.app-info-menu .app-info-menu-item {
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f4f4f4;
}
.app-info-menu .app-info-menu-item.user-info {
  cursor: pointer;
}
.app-info-menu .app-info-menu-item:last-child {
  border-bottom: 0;
}
.app-info-menu .app-info-menu-item .menu-label {
  color: #333;
}
.app-info-menu .app-info-menu-item .menu-label .anticon {
  color: #2d63ff;
  margin-right: 13px;
  font-size: 18px;
  vertical-align: middle;
}
.app-info-menu .app-info-menu-item .menu-label .label-text {
  vertical-align: middle;
}
.app-info-menu .app-info-menu-item .menu-content {
  color: #999;
}
.avatar {
  margin-right: 26px;
  vertical-align: top;
  display: inline-block;
}
.avatar .ant-avatar {
  margin-top: -4px;
}
