/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-dropdown .user-dropdown-menu {
  margin-top: -4px;
  padding: 8px 20px;
}
.ant-dropdown .user-dropdown-menu .ant-dropdown-menu-item {
  width: 170px;
}
.ant-dropdown .user-dropdown-menu .ant-dropdown-menu-item:hover,
.ant-dropdown .user-dropdown-menu .ant-dropdown-menu-submenu-title:hover {
  background: #fff;
  opacity: 0.7;
}
.ant-dropdown .user-dropdown-menu .action {
  display: flex;
  align-items: center;
  color: #333;
  font-size: 14px;
}
.ant-dropdown .user-dropdown-menu .action .action-icon {
  font-size: 18px;
}
.ant-dropdown .user-dropdown-menu .action .action-text {
  margin-left: 15px;
}
.ant-dropdown .user-dropdown-menu .action.weather {
  display: inline-flex;
  font-size: 12px;
}
.ant-dropdown .user-dropdown-menu .action.weather .weather-info {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.ant-dropdown .user-dropdown-menu .action.weather .weather-img {
  filter: brightness(0);
  opacity: 0.7;
}
.ant-dropdown .user-dropdown-menu .action.weather .w-row {
  line-height: 1;
  margin: 3px 0;
  font-size: 14px;
}
